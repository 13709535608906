.footer_t3 {
  font-family: var(--font_family_Bold);
  background-color: var(--footer_background_color) !important;
  min-height: 240px !important;
  .sub_links {
    color: var(--white);
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    font-weight: var(--font_weight_1);
    cursor: pointer;
    width: fit-content;
  }
}
