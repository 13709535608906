.tab_container {
  width: 100%;
  :global(.MuiTabs-root) {
    :global(.MuiTabs-indicator) {
      background-color: var(--dusk);
      height: 4px;
    }
    :global(.MuiTabs-flexContainer) {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    :global(.MuiTab-root.Mui-selected) {
      color: var(--dusk);
    }
    :global(.MuiTab-root) {
      color: var(--grey_color_1);
      border-bottom: 1px solid var(--grey_color_1);
    }
  }
  .tab_text {
    font-size: 15px;
    font-weight: var(--font_weight_0);
    font-family: var(--font_family_Bold);
    text-transform: capitalize;
  }
}
