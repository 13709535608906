.new_monthly_estimated_modal_block {
    width:90%;
    .new_monthly_estimated_modal_heading {
        font-family: var(--font_family_Bold);
        font-size: 20px;
        text-align: center;
        padding-bottom: 12px;
        margin-top: 16px;
    }
    .estimated_pricing {
        padding: 4px 0px 12px 0px;
        display: flex;
        margin-top: 12px;
        justify-content: space-between;
        text-align: start;
        .estimated_pricing_text {
            font-family: var(--font_family_Medium);
            font-size: 14px;
            color: var(--text_color_6);
        }

        .estimated_price_value {
            font-family: var(--font_family_Medium);
            font-size: 14px;
            color: var(--text_color_6);
        }
    }
    .monthly_estimated_bill_line {
        border-bottom: solid 1px var(--grey_color_1);
    }
    .new_monthly_estimated_modal_btn {
        margin-top: 24px;
        text-align: center;
    }
    .default_card {
        .account_review_change_card {
            font-family: var(--font_family_Semibold);
            font-size: 15px;
            text-align: center;
            color: var(--text_color);
            padding: 10px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            .review_card_img {
                padding: 0px 10px 0 0px;
            }
        }
    }
    .manage_cards_for_secondary_lines {
        text-align: center;
        padding-top: 16px;
        font-family: var(--font_family_Bold);
        font-size: 14px;
        color: var(--primary_color);
        cursor: pointer;
    }
    .one_time_charges_text {
        font-family: var(--font_family_Medium);
        font-size: 14px;
        font-weight: var(--font_weight_2);
        line-height: 1.43;
        text-align: center;
        color: var(--black);
        margin-bottom: 12px;
    }
}
.reach_border_line {
    width: 80px;
    height: 5px;
    margin: auto;
    border-radius: 2px;
    background-color: var(--success);
}
.cc_mask {
    font-family: var(--font_family_Bold);
    font-size: 15px;
    padding-bottom: 0;
    margin-bottom: 3px;
}