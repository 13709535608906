.autocomplete_dropdown_container {
  background-color: var(--white);
  z-index: 1000;
  border-radius: 2px;
  font-family: Arial, sans-serif;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 58px;
  width: 100%;
  .autocomplete_dropdown:hover {
    background-color: var(--grey98-color);
  }
  .autocomplete_dropdown {
    cursor: default;
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    border: 1px solid var(--mute_color);
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-bottom: none;
    font-size: 11px;
    color: var(--lightgrey_5);
  }
  .main_text {
    font-size: 13px;
    padding-right: 3px;
    color: var(--black);
  }
  .location_icon {
    font-size: 15px;
    margin-bottom: 5px;
  }
  &:last-child {
    .autocomplete_dropdown {
      border-bottom: 1px solid var(--mute_color);
    }
  }
}
