:root {
  --primary_color: #8d3b90;
  --white: #ffffff;
  --white_1: #f5f5f5;
  --white_2: #f0f0f0;
  --success: #3a8580;
  --default: #e8e8e8;
  --danger: #d0021b;
  --red-color_2: #c30e0e;
  --red-color_3: #912f2f;
  --red-color_4: #f44336;
  --note_danger: #ed1c24;
  --info: #996699;
  --black: #000;
  --primary_brand_color: #8d3b90;
  --primary_brand_color_light: #46918c;
  --lavender: #a976a2;
  --text_color: #313131;
  --text_color_1: #2c2c2c;
  --text_color_2: #383838;
  --text_color_3: #212529;
  --text_color_4: #404040;
  --text_color_5: #434343;
  --text_color_6: #494949;
  --text_color_7: #4a4444;
  --text_color_8: #4c5458;
  --text_color_9: #353091;
  --text_color_10: #b9b9b9;
  --text_color_11: #292929;
  --tab_color_1: #414042;
  --grey_shade: #747474;
  --grey_shade_2: #bebebe;
  --grey_shade_3: #7e7e7e;
  --grey_shade_4: #eaeaea;
  --grey_shade_5: #707679;
  --concrete-grey: #f3f3f3;
  --honey: #eeab18;
  --dusk: #48688f;
  --dusty-orange: #f58e27;
  --shocking-orange: #ef4e3e;
  --soft-peach: #ebebeb;
  --grey: #575757;
  --lightgrey: #f9f9f9;
  --lightgrey_2: #f0f0f0;
  --lightgrey_3: #f2f2f2;
  --lightgrey_4: #eee;
  --lightgrey_5: #999;
  --lightgrey_6: #f8f8f8;
  --date_picker_inp: #bbb;
  --medium-shade-grey: #404040;
  --medium-grey-shade_2: #515151;
  --select_border: #bbb;
  --border_dictator: #f08c22;
  --primary_background: #f9f4f9;
  --lite_background: #e1f0f5;
  --gray_background: #e7e7e7;
  --lite_background_BM: rgb(243, 227, 207);
  --cool_blue: #4a7bb4;
  --dark_blue: #4e759b;
  --warm_grey: #9c9c9c;
  --danger_notification: #f9e9eb;
  --info_notification: #996699;
  --default_notification: #72adec;
  --success_notification: var(--success_noti_bg);
  --warning_notification: #fff7e3;
  --subtext-color: #898989;
  --subtect-color_1: #666;
  --accordion-body_color: #686868;
  --mute_color: #e6e6e6;
  --complete: #6d6875;
  --title-color_1: #4d4d4d;
  --title-color_2: #525252;
  --radio-color: #bbbbbb;
  --card-color: #dee2e6;
  --header-color_1: #121629;
  --subheader-color_1: #585757;
  --support-color: #1aabe0;
  --light-silver_color: #d8d8d8;
  --ford-grey-color: #979797;
  --grey33-color: #545454;
  --grey90-color: #e5e5e6;
  --water-color: #d1eef9;
  --bleached_aqua: #3c8f8c;
  --bleached_aqua_2: #3d9997;
  --lavendar_color: #eaeaf0;
  --grey-seashell: #f2f1f1;
  --grey81-color: #cfcfcf;
  --grey98-color: #fafafa;
  --grey77-color: #c4c4c4;
  --grey-shade87: #878787;
  --grey-shade9d: #8c909d;
  --darkgrey_shade: #262626;
  --cyan-blue-shade: #75787b;
  --medium-cyan-shade: #9cc2bf;
  --medium-cyan-shade_2: #80d5f2;
  --cyan-blue-shade_2: #777;
  --dark-slate-blue: #378781;
  --blue-color: #0000ff;
  --chinese-silver: #ccc;
  --light-brown: #b6aca2;
  --green-shade: #1a342f;
  --cruise-blue: #33746e;
  --light-blue-shade: #5e85b2;
  --dark-charcoal: #333;
  --grey_color_1: #979797;
  --warning: #005bbb;
  --button_secondary_color: #313131;
  --lead_generation_bg_color: #fafaff;
  --font_family_Medium: "Effra-Medium";
  --font_family_Semibold: "Effra-Regular";
  --font_family_Bold: "Effra-Bold";
  --circular_loader_color: #3a8580;
  --footer_background_color: #4c5458;
  --secondary_header_link_txt_color: #ffffff;
  --footer_divider_color: #9b9b9b;
  --copyright_text_color: #a5a5a5;
  --h3_font_family: var(--font_family_Bold);
  --h3_font_size_desktop: 26px;
  --h3_font_weight: bold;
  --h3_font_size_mobile: 22px;
  --h4_font_family: var(--font_family_Semibold);
  --h4_font_size_desktop: 22px;
  --h4_font_size_mobile: 20px;
  --h4_font_weight: 600;
  --description_text_font_family: var(--font_family_Medium);
  --description_text_font_size_desktop: 20px;
  --description_secondary_text_fs_desktop: 14px;
  --description_text_font_weight: 500;
  --page_header_font_family: var(--font_family_Bold);
  --page_header_font_size_desktop: 22px;
  --page_header_font_weight: "bold";
  --main_banner_title_ff: var(--font_family_Bold);
  --main_banner_title_fw: bold;
  --main_banner_title_fs_desktop: 74px;
  --main_banner_title_fs_ipad: 36px;
  --main_banner_title_fs_mobile: 26px;
  --main_banner_sub_title_ff: var(--font_family_Semibold);
  --main_banner_sub_title_fw: semi-bold;
  --main_banner_sub_title_fs_desktop: 38px;
  --main_banner_sub_title_fs_ipad: 26px;
  --main_banner_sub_title_fs_mobile: 16px;
  --coverage_title_fs_desktop: 18px;
  --internal_banner_title_ff: var(--font_family_Bold);
  --internal_banner_title_fw: bold;
  --internal_banner_title_fs_desktop: 40px;
  --internal_banner_title_fs_ipad: 36px;
  --internal_banner_title_fs_mobile: 20px;
  --brand_feature_background: #e8f1f1;
  --help_support_links: #476690;
  --border_color: #e3e3e3;
  --bill_cal_input_bg_color: #e9ecef;
  --bill_cal_input_border_color: #ced4da;
  --select_disabled_bg_color: #e9ecef;
  --slider_bg_color: var(--dusk);
  --slider_circle_border: var(--light-blue-shade);
  --slider_popper_bg_img1: #484848;
  --slider_popper_bg_img2: #151515;
  --cement_color: #3c4043;
  --main_route_background: #fafaff;
  --border_color_2: #efedea;
  --success_noti_bg: #ddeee1;
  --success_noti_border: #208437;
  --faded_grey1: #31313133;
  --linen: #fdedeb;
  --blue_chalk: #eaeaf4;
  --green_white1: #e8e8e9;
}
@font-face {
  font-family: "SharpSansDispNo1-Medium";
  src: local("SharpSansDispNo1"),
    url("../assets/Fonts/SharpSansDispNo1-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "SharpSansDispNo1-Semibold";
  src: local("SharpSansDispNo1"),
    url("../assets/Fonts/SharpSansDispNo1-Semibold_1.ttf") format("truetype");
  font-weight: lighter;
}
@font-face {
  font-family: "SharpSansDispNo1-Bold";
  src: local("SharpSansDispNo1"),
    url("../assets/Fonts/SharpSansDispNo1-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Effra-Regular";
  src: local("Effra"),
    url("../assets/Fonts/Effra-Regular.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Effra-Medium";
  src: local("Effra"),
    url("../assets/Fonts/Effra-Medium.ttf") format("truetype");
  font-weight: lighter;
}
@font-face {
  font-family: "Effra-Bold";
  src: local("Effra"), url("../assets/Fonts/Effra-Bold.ttf") format("truetype");
  font-weight: bold;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "SharpSansDispNo1", "Effra";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text_color) !important;
}
