.container {
  width: 100%;
  .ship_page_line {
    margin: 20px auto 25px;
    width: 80px;
    height: 5px;
    border-radius: 2.5px;
    background-color: var(--primary_brand_color);
  }
  .text_fields_main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .text_fields_div {
      width: 48%;
      margin-bottom: 20px;
    }
    @media (max-width: 600px) {
      flex-direction: column;
      .text_fields_div {
        width: 100%;
      }
    }
  }
}