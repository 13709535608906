.grey_out_block {
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 1500px;
  position: absolute;
  z-index: 999;
  @media (max-width: 1440px) {
    height: 1600px;
  }
  @media (max-width: 1024px) {
    height: 1950px;
  }
  @media (max-width: 768px) {
    height: 1900px;
  }
  @media (max-width: 600px) {
    height: 1850px;
  }
}
