.data_usage_label {
  font-family: var(--font_family_Bold) !important;
  font-size: 20px !important;
  font-weight: var(--font_weight_0) !important;
  line-height: 30px !important;
}
.bill_cycle_label {
  font-family: var(--font_family_Bold) !important;
  font-size: 20px !important;
  font-weight: var(--font_weight_0) !important;
  display: flex !important;
}
.data_usage_txt {
  font-size: 30px!important;
  line-height: 1.5!important;
  font-weight: 700!important;
  display: flex!important;
  font-family: var(--font_family_Bold) !important;
}
.load_icon{
  width: 28px !important;
  margin-left:10px; 
}
.load_icon_gif {
  width: 25.67px !important;
  height: 18.67px !important;
  margin-left: 10px;
}
@media (max-width: 600px) {
  .data_usage_label {
    font-size: 20px !important;
    line-height: 1.2 !important;
  }
  .bill_cycle_label {
    font-size: 20px !important;
  }
  .data_usage_txt {
    font-size: 26px !important;
    line-height: normal !important;
  }
}

.progress_bar {
  :global(.MuiLinearProgress-bar) {
    background-color: var(--primary_brand_color) !important;
    width: 500px;
    border: 1px solid red;
  }
}
