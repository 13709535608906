.best_fit_features{
    .best_fit_line{
        width: 80px;
        height: 5px;
        border-radius: 2.5px;
        background-color: var(--primary_brand_color);
    }   
    .best_fit_features_title{
        font-family: var(--font_family_Semibold);
        font-weight: 600;
        font-size: 24px;
        color: var(--black);
    }
    .best_fit_features_sub_title{
        font-size: 18px;
        font-weight: 500;
        font-family: var(--font_family_Medium);
        color: var(--black);
    }    
}