@mixin errorMessageHelperText {
  color: var(--danger) !important;
  font-family: var(--font_family_Medium) !important;
  margin-left: 0px;
  font-size: 12px;
  line-height: 24px;
}

.TextField {
  width: 100%;
  .MuiFilledInput-input {
    height: 18px;
    padding: 9px 10px;
    background: #fff;
    border-radius: 0 !important;
    color: var(--text_color);
    font-family: var(--font_family_Medium);
  }
  .MuiInputLabel-filled {
    z-index: 1;
    transform: translate(10px, 8px) scale(1);
    color: var(--warm_grey);
    width: 90%;
    font-family: var(--font_family_Medium);
    font-size: 17px;
    @media (max-width: 600px) {
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
    }
  }
  .MuiFormHelperText-root {
    @include errorMessageHelperText();
  }
  .MuiFilledInput-root:hover {
    border-color: var(--primary_brand_color);
  }
  .MuiFilledInput-root {
    border-radius: 0 !important;
    border: 1px solid var(--grey_shade_5);
    // border: none;
    height: 38px;
    // background-color: #ffffff;
  }
  .MuiFilledInput-root.Mui-error {
    border-color: var(--danger);
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    z-index: -1;
  }
  .MuiFilledInput-underline:hover:before,
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border: none !important;
  }
  .MuiFilledInput-underline.Mui-disabled:before {
    border: none;
  }
  .MuiInputBase-input.Mui-disabled {
    background-color: #eee;
  }
}
