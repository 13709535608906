.lead_generation {
  background: var(--lead_generation_bg_color);
  .lead_generation_title {
    font-family: var(--font_family_Medium);
    color: var(--text_color_8);
  }
  .lead_generation_subtitle {
    font-family: var(--font_family_Semibold);
    color: var(--text_color_8);
    text-align: center;
  }
  .input_field {
    border: 1px solid var(--white) !important;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.05),
      0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }
  .input_field > div {
    border: none !important;
    border-radius: 0 !important;
  }
  .email_success {
    .email_success_text {
      font-family: var(--font_family_Semibold);
      font-weight: var(--font_weight_2);
    }
  }
  .keep_in_touch_text {
    font-weight: var(--font_weight_2);
    font-family: var(--font_family_Semibold);
  }
}
