.service_content {
  .service_content_main,
  .service_main {
    background-color: var(--white_1) !important;
    font-family: var(--font_family_medium) !important;
  }
  a {
    color: var(--dusk) !important;
    font-weight: bold;
    text-decoration: none;
  }
  .is_paragraph {
    margin: 0 0 0 10px;
    font-family: var(--font_family_medium) !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: normal;
    color: var(--text_color_2);
    margin-bottom: 10px;
  }
  .dot {
    background-color: var(--success);
    height: 4px;
    width: 4px;
    border-radius: 50%;
    margin-bottom: 10px;
    margin-left: 40px;
  }
  .service_header_text,
  .service_header {
    font-family: var(--font_family) !important;
    font-size: 18px;
    color: var(--black);
    font-weight: var(--font_weight_4);
    margin-bottom: 15px;
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .service_content {
    margin-top: 70px !important;
  }
}
