.footer_t2 {
  font-family: var(--font_family_Bold);
  background-color: var(--footer_background_color) !important;

  .logo_style {
    width: 178px;
    height: 52px;
    @media (max-width: 600px) {
      width: 120px;
      height: 35px;
      margin: 10px 0px;
    }
  }
  .subLinks {
    color: var(--white);
    font-family: var(--font_family_Medium);
    font-size: 14px;
    font-weight: var(--font_weight_1);
  }
  .disclaimer_text {
    font-size: 13px;
    font-weight: var(--font_weight_1);
    line-height: 1.38;
    color: var(--white);
    font-family: var(--font_family_Medium);
  }
  .copyright_text {
    font-size: 13px;
    font-weight: var(--font_weight_1);
    line-height: 1.38;
    letter-spacing: -0.1px;
    color: var(--white);
    font-family: var(--font_family_Medium);
  }
  .version_indicator {
    color: var(--grey-shade9d);
    font-size: 13px;
    margin-left: 13px;
  }
  .subLinkHightlight {
    font-family: var(--font_family_Semibold);
    font-size: 12px;
    background-color: var(--primary_color);
    color: var(--white);
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    width: max-content;
  }

  @media (max-width: 320px) {
    .subLinks {
      display: flex;
      flex-direction: column;
    }
    .subLinkHightlight {
      margin: 0px !important;
    }
  }
  @media (min-width: 768px) and (max-width: 900px) {
    .footerList_st {
      padding-left: 1rem;
    }
  }
}
