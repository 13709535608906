@mixin buttonStyle($height, $bgcolor, $border, $color, $font-size, $text-transform, $text-decoration, $borderRadius, $min-width) {
  height: $height !important;
  background-color: $bgcolor !important;
  border: $border !important;
  color: $color !important;
  font-size: $font-size !important;
  font-weight: var(--font_weight_1) !important;
  line-height: 1.125em !important;
  text-align: center !important;
  letter-spacing: 0 !important;
  font-family: var(--font_family_Bold) !important;
  text-transform: $text-transform !important;
  text-decoration: $text-decoration !important;
  border-radius: $borderRadius !important;
  min-width: $min-width !important;

  :global(.MuiCircularProgress-colorPrimary) {
    color: $color !important;
    width: 35px !important;
    height: 35px !important;
  }
}

.custom_primary_underlined {
  @include buttonStyle(
    40px, 
    transparent, 
    none,
    var(--primary_color),
    16px,
    capitalize,
    underline,
    0,
    100px);
}

.custom_primary_contained_square {
  @include buttonStyle(
    40px, 
    var(--primary_color), 
    none,
    var(--white),
    16px,
    uppercase,
    none,
    0,
    120px);
}

.custom_disabled_button {
  opacity: 0.5;
  // background-color: var(--primary_color) !important;
  cursor: not-allowed !important;
  pointer-events: auto !important;
}
