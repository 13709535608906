@mixin text_style($fontSize, $fontWeight){
    font-size: $fontSize !important;
    font-weight: $fontWeight !important;
}
.data_usage_container {
  @media (max-width:1024px){
    padding : 30px 7%;
  }
  @media (max-width:768px){
    padding : 30px 3%;
  }
  padding : 30px 15%;
  :global(.data_usage_label) {
    @include text_style(14px,normal);
    line-height: 1.5 !important;
    font-family: var(--font_family_Semibold);
  }
  :global(.bill_cycle_label) {
    @include text_style(16px,normal);
    display: flex !important;
    line-height: 1.5 !important;
    font-family: var(--font_family_Semibold);
  }
  :global(.data_usage_txt) {
    font-family: var(--font_family_Bold) !important;
    line-height: 1.5 !important;
    display: flex !important;
    @include text_style(20px,bold);
  }
  :global(.load_icon){
    width: 20px !important;
    height: 20px !important;
    margin-left:10px;
  }
  :global(.load_icon_gif) {
    width: 18.33px !important;
    height: 13.33px !important;
    margin-left: 10px;
  }
}
@media (max-width: 600px) {
  .data_usage_container {
    :global(.data_usage_label) {
      font-size: 20px !important;
      line-height: 1.2 !important;
    }
    :global(.bill_cycle_label) {
      font-size: 20px !important;
    }
    :global(.data_usage_txt) {
      font-size: 26px !important;
      line-height: normal !important;
    }
  }
}
:global(.progress_bar) {
  :global(.MuiLinearProgress-bar) {
    background-color: var(--primary_brand_color) !important;
  }
}
.account_card_expiry_message {
  font-family: var(--font_family_Semibold);
  font-size: 14px;
  margin: 5px 0 15px;
  text-align: center;
  span:nth-child(1) {
      color: var(--danger);
  }
}
.account_update_expiry_card {
  font-family: var(--font_family);
  font-size: 14px;
  cursor: pointer;
  color: var(--primary_color);
}