.schedule_installation_container {
  .calender {
    .react-datepicker-wrapper {
      width: 100% !important;
      border: solid 1px #e5e4e4 !important;
      .react-datepicker__input-container {
        height: 100% !important;
        display: flex !important;
        font-size: 14px !important;
        input {
          box-sizing: border-box !important;
          height: 100% !important;
          border: none !important;
          padding: 10px !important;
          width: 100% !important;
          font-size: 16px;
        }
      }
    }
  }
}

.react-datepicker {
  width: 100%;
  border-radius: 0;
  border: 0;

  .react-datepicker__navigation {
    .react-datepicker__navigation-icon {
      .react-datepicker__navigation-icon--previous {
        // left: 25px !important;
      }
      .react-datepicker__navigation--next {
        // right: 35px !important;
      }
    }
  }
  .react-datepicker__month-container {
    width: 100%;
    .react-datepicker__header {
      font-family: var(--font_family_Semibold) !important;
      color: var(--primary_color) !important;
      padding: 0 !important;
      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        border-right: 1px solid var(--ford-grey-color);
        font-family: var(--font_family_Semibold) !important;
        color: var(--primary_color) !important;
        border-left: 1px solid var(--ford-grey-color);
        border-bottom: 1px solid var(--ford-grey-color);
        background-color: #e6d9e9;
        height: 34px;
        padding: 8px 0;
        box-sizing: border-box;
      }
      .react-datepicker__day-names {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;
        height: 34px !important;
        background-color: var(--white) !important;
        .react-datepicker__day-name {
          flex: 1 1 auto;
          position: relative;
          text-align: center;
          line-height: 40px;
        }
        .react-datepicker__day-name + .react-datepicker__day-name {
          // border-left: solid 1px white;
        }
        .react-datepicker__day-name:first-child {
          border-left: 1px solid var(--ford-grey-color);
        }

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
          width: 2.3rem;
          color: var(--primary_color);
          border-right: 1px solid var(--ford-grey-color);
          margin: 0;
          line-height: 2.3rem;
        }
      }
    }
    .react-datepicker__month {
      margin: 0 0 0 !important;
      .react-datepicker__day-names,
      .react-datepicker__week {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        list-style: none;
        margin: 0;
        padding: 0;

        border-bottom: 1px solid var(--ford-grey-color);
        height: 34px;

        .react-datepicker__day {
          flex: 1 1 auto;

          position: relative;
          text-align: center;
          line-height: 40px;
        }
        .react-datepicker__day--disabled {
          color: var(--lightgrey_5) !important;
        }
        .react-datepicker__day--disabled:hover {
          background-color: unset !important;
        }
        .react-datepicker__day + .react-datepicker__day {
          // border-left: solid 1px white;
        }
        .react-datepicker__day:first-child {
          border-left: 1px solid var(--ford-grey-color);
        }
        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
          width: 2.3rem;
          height: 100%;
          font-family: var(--font_family_Semibold);
          font-size: 13px !important;
          color: var(--text_color_8);
          margin: 0 !important;
          border-right: 1px solid var(--ford-grey-color);
          line-height: 2rem;
        }
        .react-datepicker__day:hover,
        .react-datepicker__month-text:hover,
        .react-datepicker__quarter-text:hover,
        .react-datepicker__year-text:hover {
          background-color: #e6d9e9;
          border-radius: 0 !important;
        }
        .react-datepicker__day--selected {
          color: var(--white) !important;
          background-color: var(--primary_color) !important;
          border-radius: 0 !important;
        }
        .react-datepicker__day--in-selecting-range {
          background-color: #e6d9e9;
          border-radius: 0 !important;
        }
        .react-datepicker__day--in-range {
          background-color: #e6d9e9;
          border-radius: 0 !important;
        }
        .react-datepicker__day--range-end {
          color: var(--white) !important;
          background-color: var(--primary_color) !important;
          border-radius: 0 !important;
        }
        .react-datepicker__day--keyboard-selected {
          background-color: unset !important;
          border-radius: unset;
        }
      }
    }
  }
}
