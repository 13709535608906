.header_modal {
  z-index: 1111 !important;
  .header_container {
    width: 100%;
  }

  :global(.MuiBackdrop-root) {
    background-color: var(--white) !important;
  }

  .dialog_container {
    padding-bottom: 20px;
    .active_link_item {
      color: var(--primary_brand_color) !important;
      font-family: var(--font_family_Bold) !important;
      cursor: pointer;
    }
    .link_item {
      font-size: 16px;
      font-family: var(--font_family_Bold);
      color: var(--primary_brand_color);
      width: fit-content;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-top: 1px solid var(--ford-grey-color);
      width: 100%;
      padding-left: 20px;
      box-sizing: border-box;
    }
    .secondary_header {
      color: var(--primary_brand_color);
      .label {
        font-family: var(--font_family_Semibold);
        font-size: 16px;
        font-weight: var(--font_weight_2);
        color: var(--text_color);
        margin-top: 13px;
      }
    }
  }
}
