.plan_features{
    background-color: var(--lite_background);
    .featureHeader{
        background-color: var(--primary_background);
        color: var(--white);
    }
    .plan_features_title{
        font-size: 18px;
        font-family: var(--font_family_Medium);
    }
}