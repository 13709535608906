.activation_conatiner {
  padding: 50px 100px 100px;

  @media (max-width: 1024px) {
    padding: 30px 40px;
  }

  @media (max-width: 600px) {
    padding: 30px 10px;
  }
}

.heading_conatiner {
  margin-bottom: 50px;
  position: relative;

  .back_arrow_icon {
    position: absolute;
    top: 10px;
    left: 0;
  }

  .heading {
    font-family: var(--font_family_Medium);
    font-size: 38px;
    color: var(--text_color);
    text-align: center;

    @media (max-width: 600px) {
      font-size: 26px;
    }
  }

  .setup_video {
    position: absolute;
    bottom: 10px;
    right: 0;
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--primary_brand_color);
    cursor: pointer;

    .setup_video_txt {
      text-decoration: underline;
      font-family: var(--font_family_Semibold);
      font-size: 16px;
    }

    @media (max-width: 600px) {
      font-size: 26px;
      bottom: -30px;
      left: 30%;
    }
  }
}

.progress_bar_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .progress_bar {
    width: 74px;
    height: 5px;
    margin-right: 10px;
    background-color: var(--primary_brand_color);
  }

  .addOpacity {
    opacity: 0.5;
  }
}

.steps_container {
  background-color: var(--white);
  width: 779px;
  height: 811px;
  margin: 35px auto 50px;
  padding: 50px 60px 0;
  position: relative;
  box-sizing: border-box;
  @media (max-width: 1024px) {
    width: 90%;
    height: auto;
    padding: 50px 30px;
  }

  @media (max-width: 600px) {
    width: 80%;
    height: auto;
    padding: 50px 30px;
  }

  .left_arrow_icon,
  .right_arrow_icon {
    position: absolute;
    top: 200px;
    cursor: pointer;

    @media (max-width: 600px) {
      img {
        width: 31px;
        height: 31px;
      }
    }
  }

  .left_arrow_icon {
    left: -3%;
  }

  .right_arrow_icon {
    right: -3%;
  }

  .disableIcon {
    opacity: 0.5;
    pointer-events: none;
  }

  .act_title {
    font-family: var(--font_family_Medium);
    font-size: 22px;
    color: var(--text_color);
    text-align: center;
  }

  .step_img {
    margin: 50px 0;
    text-align: center;

    @media (max-width: 600px) {
      img {
        width: 250px;
        height: 214px;
      }
    }
  }

  .act_steps {
    position: relative;

    img {
      position: absolute;
      top: 7px;
    }

    .act_step {
      line-height: 24px;
      margin-bottom: 15px;
      font-size: 16px;
      font-family: var(--font_family_Semibold);
      color: var(--text_color);
      margin-left: 30px;
    }
  }

  .s2_note {
    font-size: 16px;
    font-family: var(--font_family_Semibold);
    color: var(--text_color);
    margin-left: 30px;
  }
}

.activate_btn {
  text-align: center;
  font-size: 20px !important;
  min-width: 220px !important;
}

.act_modal_wrapper {
  width: 50% !important;
  background-color: var(--white);

  @media (max-width: 1024px) {
    width: 75% !important;
  }

  @media (max-width: 600px) {
    width: 95% !important;
  }
}

.act_modal_conatiner {
  padding: 20px 50px;
  text-align: center;

  @media (max-width: 600px) {
    padding: 0px;
  }

  .act_modal_title {
    font-size: 26px;
    color: var(--text_color_8);
    font-family: var(--font_family_Bold);
  }

  .act_modal_desc {
    font-size: 16px;
    color: var(--text_color);
    font-family: var(--font_family_Semibold);
    margin: 25px 0;
    line-height: 22px;
  }

  .act_modal_input_conatiner {
    text-align: center;
    margin: 0 auto;
    width: 70%;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .act_modal_input_title {
    text-align: start;
    position: relative;
    font-size: 16px;
    font-family: var(--font_family_Semibold);
    color: var(--text_color);
    margin-bottom: 15px;

    img {
      position: absolute;
      bottom: 2px;
      margin-left: 5px;
    }
  }

  .confirm_btn {
    margin: 30px 0 25px;
    width: 180px !important;
  }

  .cancel_txt {
    font-size: 16px;
    font-family: var(--font_family_Semibold);
    text-decoration: underline;
    color: var(--primary_brand_color);
    cursor: pointer;
  }

  .act_modal_textfield > label {
    text-align: start;
    font-size: 18px;
    font-family: var(--font_family_Semibold);
  }
}

.act_fail_modal_wrapper {
  width: 35% !important;
  background-color: var(--white);

  @media (max-width: 1024px) {
    width: 55% !important;
  }

  @media (max-width: 600px) {
    width: 95% !important;
  }
}

.act_fail_modal_conatiner {
  padding: 10px;
  text-align: center;

  @media (max-width: 600px) {
    padding: 0px;
  }

  .act_fail_modal_title {
    font-size: 24px;
    font-family: var(--font_family_Bold);
    margin-bottom: 30px;
  }

  .act_fail_modal_desc {
    font-size: 16px;
    color: var(--text_color);
    font-family: var(--font_family_Medium);
    margin: 15px 0 0;
    line-height: 24px;
  }

  .confirm_btn {
    margin: 30px 0 25px;
  }
}

.loader_container {
  width: 20% !important;
  background-color: var(--white);

  @media (max-width: 1024px) {
    width: 30% !important;
  }

  @media (max-width: 600px) {
    width: 50% !important;
  }
}

.loader_title {
  font-size: 16px;
  font-family: var(--font_family_Medium);
}

.loader_status {
  font-size: 12px;
  font-family: var(--font_family_Semibold);
}

.act_loader {
  margin: 20px 0 15px;
}
