.privacy_main_container {
  margin: 0px auto 0px auto;
  .privacy_content {
    color: var(--text_color11);
    p {
      margin-top: 25px !important;
      font-family: var(--font_family_Semibold) !important;
    }
    a {
      text-decoration: underline;
      color: var(--primary_brand_color);
      font-family: var(--font_family_Semibold);
      cursor: pointer;
      clear: left;
      word-break: break-word;
    }
    .privacy_content_container {
      padding: 50px 70px;
      @media (max-width: 600px) {
        padding: 20px 15px;
      }
    }
    .privacy_title {
      font-size: 72px !important;
      line-height: 1.43;
      padding: 70px 85px;
      color: var(--white);
      font-family: var(--font_family_Medium);
      background-color: var(--primary_brand_color);
      @media (max-width: 600px) {
        padding: 50px 15px;
      }
    }
    .privacy_heading {
      font-size: 26px !important;
      font-family: var(--font_family_Semibold) !important;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 25px;
      text-transform: uppercase;
    }
    div:nth-child(1) .privacy_heading {
      color: var(--primary_brand_color);
      font-size: 42px !important;
      font-family: var(--font_family_Semibold) !important;
      margin-bottom: 50px;
      text-transform: none;
    }
    .privacy_text {
      font-family: var(--font_family_Semibold);
      font-size: 22px;
      font-weight: normal;
      line-height: 26px;
      letter-spacing: normal;
      margin-bottom: 50px;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
}
