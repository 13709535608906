.activation_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .activation_header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0px 15px 0px;
    .arrow_icon {
      cursor: pointer;
      width: 24px;
      height: 24px;
      z-index: 100;
      left: 0;
      position: absolute;
    }
    .title_main {
      font-family: var(--font_family_Bold);
      font-size: 22px;
      font-weight: var(--font_weight_4);
      line-height: 1;
      text-align: center;
      color: var(--text_color);
    }
  }
  .initial_activation_main {
    display: flex;
    flex-direction: column;
    .title_main_activation {
      font-family: var(--font_family_Semibold);
      font-size: 14px;
      font-weight: var(--font_weight_2);
      line-height: 1.43;
      text-align: center;
      color: var(--text_color_2);
    }
    .activation_body_content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px 50px;
      .activation_info {
        display: flex;
        width: 100%;
        margin: 0 0 15px;
        .check_icon {
          width: 15px;
          height: 15px;
          margin-top: 3px;
        }
        .activation_body_text {
          box-sizing: border-box;
          width: calc(100% - 15px);
          padding-left: 10px;
          font-family: var(--font_family_Medium);
          font-size: 14px;
          font-weight: var(--font_weight_1);
          line-height: 1.43;
          color: var(--text_color);
        }
      }
      .btn {
        display: flex;
        justify-content: center;
        width: 30%;
        padding-top: 10px;
      }
    }
  }
  .title {
    color: var(--dusk);
    font-size: 16px;
    font-weight: var(--font_weight_0);
    box-sizing: border-box;
    font-family: var(--font_family_Bold);
    padding-top: 3px;
  }
  .question_text {
    font-family: var(--font_family_Semibold) !important;
  }
  .arrow_icon_right {
    cursor: pointer;
    height: 0.7rem;
    width: 0.7rem;
    padding-top: 0.5rem;
    padding-left: 0.3rem;
  }
  .link_text {
    color: var(--primary_color);
    font-weight: var(--font_weight_4);
    cursor: pointer;
  }
}
