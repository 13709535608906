.data_pack {
  font-size: 14px;
  font-weight: 600;
  color: var(--black);
  font-family: var(--font_family_Semibold);
  font-weight: var(--font_weight_2);
}
.arrow_icon {
  width: 18px;
  height: 18px;
  margin: 10px 5px 5px 10px;
}
.data_note {
  font-family: var(--font_family_Semibold);
  font-weight: var(--font_weight_2);
}
.plan_change_text{
  color: var(--primary_color) !important;
  font-family: var(--font_family_Semibold) !important;
  font-size: 14px !important;
  text-align: center !important;
}
.tabs_container {
  font-weight: 400;
  @media (min-width: 1024px) {
    width: 35%;
  }
  @media (max-width: 1024px) {
    width: 60%;
  }
  @media (max-width: 1023px) {
    width: 567px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
}
.plans_container {
  @media (min-width: 768px) {
    width: 526px;
  }
  @media (max-width: 768px) {
    width: 526px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
}
.account_card_expiry_message {
  font-family: var(--font_family_Semibold);
  font-size: 14px;
  margin: 5px 0 15px;
  text-align: center;
  span:nth-child(1) {
      color: var(--danger);
  }
}
.account_update_expiry_card {
  font-family: var(--font_family);
  font-size: 14px;
  cursor: pointer;
  color: var(--primary_color);
}
