.new_monthly_statement_modal_block {
    width: 90%;
    .new_monthly_statement_modal_heading {
        font-family: var(--font_family_Bold);
        margin-top: 16px;
        font-size: 20px;
        text-align: center;
        padding-bottom: 12px;
    }
    .current_pricing {
        padding: 12px 0px 12px 0px;
        display: flex;
        margin-top: 24px;
        justify-content: space-between;
        text-align: start;
        .current_pricing_text {
            font-family: var(--font_family_Medium);
            font-size: 14px;
            color: var(--text_color_6);
            .current_plan_details {
                font-family: var(--font_family_Medium);
                font-size: 14px;
                line-height: 1;
                color: var(--text_color_6);
                margin-top: 16px;
            }
        }

        .current_price_value {
            font-family: var(--font_family_Medium);
            font-size: 14px;
            line-height: 1;
            color: var(--text_color_6);
            margin-top: 24px;
        }
    }
    .reach_border_line_new {
        border-bottom: solid 1px var(--grey_color_1);
    }
    .new_monthly_statement_modal_btn {
        margin-top: 24px;
        text-align: center;
    }
}
.reach_border_line {
    width: 80px;
    height: 5px;
    margin: auto;
    border-radius: 2px;
    background-color: var(--success);
}