.item_container {
  font-size: 16px;
  margin: 0 15px 0 0;
  // padding: 15px 0 15px 0;
  text-align: center;

  :global(.MuiButtonBase-root) {
    color: var(--text_color);
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    font-weight: var(--font_weight_2);
  }

  :global(.MuiButtonBase-root:hover) {
    color: var(--primary_brand_color);
    // background: var(--white);
  }

  :global(.MuiButton-root:active),
  :global(.MuiButton-root:hover) {
    // background: var(--white);
  }

  .arrow_up_key {
    fill: var(--primary_brand_color);
  }

  .header_btn_txt {
    font-family: var(--font_family_Semibold);
  }

  .header_menu {
    :global(.MuiPaper-root),
    :global(.MuiMenu-paper),
    :global(.MuiPopover-paper),
    :global(.MuiPaper-elevation8),
    :global(.MuiPaper-rounded) {
      // margin-top: 18px;
    }

    :global(.MuiList-root):global(.MuiMenu-list),
    :global(.MuiList-padding) {
      padding: 0;
    }

    :global(.MuiMenuItem-root) {
      padding: 10px 20px;
    }

    :global(.MuiButtonBase-root:hover) {
      color: var(--white) !important;
      background: var(--primary_brand_color);
    }

    :global(.MuiListItem-button) {
      background: #fff;
    }

    :global(.MuiListItem-button:hover) {
      background-color: var(--primary_brand_color) !important;
    }

    :global(.MuiPaper-rounded) {
      border-radius: 0;
    }

    .menu_item {
      font-family: var(--font_family_Semibold) !important;
      display: flex;
      font-size: 14px;
    }
  }

  .active_toggle_button:global(.MuiButtonBase-root) {
    color: var(--primary_brand_color);
  }
  @media (max-width: 600px) {
    margin: 0 !important;
  }
}

.active_header_item {
  border-bottom: 4px solid var(--primary_brand_color);
}
