.caboAccContainer {
  padding: 100px 15px 30px 15px;
  height: 100%;
  margin: 0px 100px 0px 100px;
  background: var(--main_route_background);
  @media (min-width: 768px) and (max-width: 1024px) {
    margin: 0px 20px 0px 20px;
    padding: 50px 15px 30px 15px;
  }
  @media (max-width: 600px) {
    margin: 0;
    height: auto;
    padding: 20px 10px 20px 10px;
  }
  .caboGrid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px 20px 0px;
    @media (max-width: 768px) {
      align-items: center;
    }
    @media (max-width: 600px) {
      gap: 60px;
      flex-direction: column;

      margin: 20px 0px 20px 0px;
    }
    .heading {
      font-size: 38px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 43.2px;
      font-family: var(--font_family_Medium);
      color: var(--text_color_8);
      @media (min-width: 768px) and (max-width: 1024px) {
        color: var(--text_color_8);
        font-size: 28px;
        line-height: 32px;
      }
      @media (max-width: 600px) {
        font-size: 20px;
      }
    }
    .idContainer {
      height: 70px;
      width: 358px;
      border: 2px solid var(--border_color_2);
      background-color: var(--lead_generation_bg_color);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 14px 34px 27px rgba(0, 0, 0, 0.03);
      color: var(--text_color);
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
      @media (min-width: 768px) and (max-width: 1024px) {
        height: 50px;
        width: 250px;
      }
      @media (max-width: 600px) {
        height: 40px;
        width: 200px;
      }
      span {
        color: var(--primary_color);
        margin-left: 3px;
        font-family: var(--font_family_Bold);
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
      }
    }
  }
  .boxContainer {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    @media (max-width: 600px) {
      margin-bottom: 40px;
      flex-direction: column;
    }
    .detailBox {
      height: 338px;
      width: 344px;
      padding: 33px 25px 33px 0px;
      @media (max-width: 600px) {
        padding: 0;
      }
      .innerBox {
        height: 100%;
        width: 100%;
        padding: 10px;
        border: 2px solid var(--border_color_2);
        background-color: var(--white);
        @media (max-width: 600px) {
          padding: 5px;
        }
        .innerContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 60px;
          .boxHeading {
            color: var(--text_color);
            font-family: var(--font_family_Medium);
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 26px;
            .onlineStatus {
              margin-left: 10px;
              span {
                margin-left: 5px;
                color: var(--light_green);
                font-family: var(--font_family_Bold);
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 18px;
              }
            }
          }
          .sideBlock {
            padding: 10px;
            margin: 10px;
            background-color: rgba(141, 59, 144, 0.1);
            .sideText {
              color: var(--primary_color);
              font-family: var(--font_family_Medium);
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 16px;
            }
          }
          .rebootText {
            margin: 10px;
            color: var(--primary_color);
            font-family: var(--font_family_Semibold);
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 16px;
            text-decoration: underline;
            cursor: pointer;
          }
          .loaderImg {
            margin-right: 20px;
          }
        }
        .detailsCont {
          height: 70%;
          background-color: var(--main_route_background);
          padding: 15px;
          .detailsHeadCont {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .detailsHead {
              color: var(--text_color_8);
              font-family: var(--font_family_Medium);
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 24px;
              text-transform: uppercase;
            }
            span {
              color: var(--primary_color);
              font-family: var(--font_family_Medium);
              font-weight: 500;
              letter-spacing: 0;
              line-height: 28px;
            }
          }
          .hLine {
            height: 1px;
            width: 100%;
            margin: 10px 0px 20px 0px;
            border: 1px solid rgba(0, 0, 0, 0.06);
          }
          .info {
            div {
              margin-bottom: 15px;
              display: flex;
              justify-content: start;
              align-items: center;
              color: var(--text_color_11);
              font-family: var(--font_family_Semibold);
              font-size: 16px;
              letter-spacing: 0;
              line-height: 19px;
            }
            img {
              margin-right: 10px;
            }
          }
          .modemDetails {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .macAdd {
              font-family: var(--font_family_Semibold);
              color: var(--text_color);
              font-size: 16px;
              letter-spacing: 0;
              line-height: 18px;
              span {
                margin: 2px;
              }
              .macAddress {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 18px;
                font-family: var(--font_family_Medium);
              }
              @media (max-width: 768px) {
                display: flex;
                width: 100%;
                justify-content: space-around;
              }
            }
            .changeModemBtn {
              margin-top: 20px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}
.textField {
  text-align: left;
}
.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .loadingBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
  }
  .modalTitle {
    color: var(--text_color_8);
    font-family: var(--font_family_Bold);
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    margin-bottom: 30px;
  }
  .rebootModalCont {
    .rebootDesc {
      width: 75%;
      text-align: center;
      margin: 0 auto 20px;
      font-family: var(--font_family_Semibold);
      @media (max-width: 768px) {
        width: 90%;
      }
    }
  }
  .textFieldCont {
    width: 100%;
    margin-bottom: 20px;
    .label {
      color: var(--text_color);
      font-family: var(--font_family_Semibold);
      font-size: 16px;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      img {
        margin-left: 5px;
      }
    }
    .textField {
      display: flex;
      img {
        position: relative;
        right: 35px;
        border-left: 1px solid black;
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;
      }
      .acc_modal_textfield {
        text-align: start;
        font-size: 18px;
        font-family: var(--font_family_Semibold);
      }
    }
  }
  .confirmBtn {
    margin-bottom: 25px;
  }
  .cancel {
    color: var(--primary_color);
    font-family: var(--font_family_Semibold);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    text-decoration: underline;
    cursor: pointer;
  }
}
