.currentPlan {
  color: var(--primary_brand_color);
  margin: 5px 0;
  font-family: var(--font_family_Semibold);
}
.active_plan {
  cursor: not-allowed !important;
}
.in_active_plan {
  cursor: pointer;
}
.active_item_label {
  color: var(--black) !important;
  font-family: var(--font_family_Bold) !important;
  margin-bottom: 5px;
}
.move_next_month_label {
  color: var(--date_picker_inp);
  font-size: 14px;
  font-family: var(--font_family_Semibold);
  margin-top: 10px;
}

.ActiveCard {
  background: var(--primary_brand_color) !important;
  color: var(--white) !important;
}

.CheckoutCard {
  border: 1px solid var(--card-color);
  border-radius: 4px;
  padding: 15px;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  color: var(--text_color);

  .leftDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    width: 40%;

    .cardTitle {
      font-size: 18px;
      font-family: var(--font_family_Semibold);
    }

    .cardSubtext {
      font-size: 20px;
    }
  }

  .rightDiv {
    width: 60%;

    .gbData {
      font-family: var(--font_family_Bold);
      font-size: 16px;
      text-align: right;

      .monthData {
        font-family: var(--font_family_Bold);
        color: var(--grey_shade);
        font-size: 14px;
      }

      .monthDataActive {
        font-family: var(--font_family_Bold);
        color: var(--white);
        font-size: 14px;
      }
    }

    .sharable {
      font-size: 12px;
      font-family: var(--font_family_Semibold);
      text-align: right;

      margin: 5px 0px;
    }

    .addLine {
      font-family: var(--font_family_Semibold);
      font-size: 12px;
      text-align: right;

      margin: 5px 0px;
    }
  }
}

.CheckoutCardActive {
  border: 1px solid var(--primary_brand_color);
  border-radius: 4px;
  padding: 15px;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  color: var(--text_color);

  .leftDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    width: 40%;

    .cardTitle {
      font-size: 18px;
      font-family: var(--font_family_Semibold);
    }

    .cardSubtext {
      font-size: 20px;
    }
  }

  .rightDiv {
    width: 60%;

    .gbData {
      font-family: var(--font_family_Bold);
      font-size: 16px;
      text-align: right;

      .monthData {
        font-family: var(--font_family_Bold);
        color: var(--grey_shade);
        font-size: 14px;
      }

      .monthDataActive {
        font-family: var(--font_family_Bold);
        color: var(--white);
        font-size: 14px;
      }
    }

    .sharable {
      font-size: 12px;
      font-family: var(--font_family_Semibold);
      text-align: right;

      margin: 5px 0px;
    }

    .addLine {
      font-family: var(--font_family_Semibold);
      font-size: 12px;
      text-align: right;

      margin: 5px 0px;
    }
  }
}

.selectPlanAndProceedBtn {
  width: 60%;
  margin: 20px auto;
}

.active_plan#actvie_card_border > .CheckoutCard {
  border: 2px solid var(--success);
}
