.team_intro_main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 100px;
  .team_intro_text_div {
    margin-right: 30px;
    .team_intro_title {
      text-align: left;
    }
    .team_intro_description {
      font-size: 15px;
      line-height: 1.6;
      font-weight: var(--h4_font_weight);
      font-family: var(--font_family_Semibold);
      text-align: left;
      margin-top: 30px;
    }
    .divider {
      justify-content: flex-start;
    }
  }
  .team_intro_images_div {
    display: flex;
    flex-direction: row;
    .desktop_image_div1 {
      display: flex;
      flex-direction: column;
    }
    .desktop_image_div2 {
      display: flex;
      flex-direction: column;
      .desktop_image_div2_inner {
        display: flex;
        flex-direction: row;
      }
    }
    .desktop_image {
      margin: 0 0 25px 25px;
    }
    .mobile_img_div {
      display: none;
    }
  }
  @media screen and (max-width: 1024px) {
    margin: 40px;
    flex-direction: column;
    .team_intro_text_div {
      margin-bottom: 30px;
      margin-right: 0;
      .divider {
        justify-content: center;
      }
      .team_intro_title {
        text-align: center;
      }
      .team_intro_description {
        text-align: center;
      }
    }
    .team_intro_images_div {
      flex-direction: column;
      .desktop_image_div1 {
        flex-direction: row;
      }
      .iPad_images_div {
        display: block;
        .iPad_images_inner {
          display: flex;
          flex-direction: row;
          .iPad_img_left {
            width: 45%;
            padding-right: 30px;
          }
          .iPad_img_right {
            width: 55%;
          }
        }
        .iPad_img {
          width: 100%;
          height: 100%;
        }
      }
      .mobile_img_div {
        display: none;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .iPad_images_inner:nth-child(2) {
      flex-direction: column !important;
      .iPad_img_left {
        width: 100% !important;
        padding-right: 0 !important;
        line-height: 0;
      }
      .iPad_img_right {
        margin-top: 30px;
        width: 100% !important;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .mobile_img_div {
      display: flex !important;
      flex-direction: column;
      width: 100%;
      .mobile_img {
        width: 100%;
        height: 100%;
        margin-bottom: 30px;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
    .team_intro_images_div {
      width: 100%;
    }
    .desktop_image,
    .iPad_images_div {
      display: none !important;
    }
  }
}
