.coverage_check_heading {
  color: var(--text_color_8);
  font-family: var(--font_family_Bold) !important;
}

.coverage_check_subheading {
  color: var(--text_color);
  font-family: var(--font_family_Medium);
  font-size: 16px;
  text-align: center;
}

.address_holder {
  display: flex;
  height: 42px;
  box-sizing: border-box;
  border: 1px solid var(--primary_color);
  background-color: var(--white);
  width: 100%;
  margin-top: 25px;
  .css-1dbhjl6-MuiContainer-root {
    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
      .TextField {
        .MuiFilledInput-root {
          border-radius: 0 !important;
          border: none !important;
        }
      }
    }
  }
}
.coverage_error {
  font-size: 14px;
  font-family: var(--font_family_Bold);
  color: var(--text_color);
  margin-top: 10px;
}
.cancel_btn {
  color: var(--primary_color);
  font-family: var(--font_family_Medium);
}
.textField > div {
  border-radius: 0 !important;
  border: none !important;
}
