.lead_generation{
  background: var(--lead_generation_bg_color);
  object-fit: contain;
  padding: 70px 0;
  margin: 0 20px;
  .lead_generation_title {
      font-family: var(--font_family_Bold);
      color: var(--text_color);
      @media (max-width: 600px) {
          font-size: 22px;
          padding: 0 10px;
      }
  }
  .lead_generation_subtitle{
      margin: 20px 32px 29px 33px;
      font-family: var(--font_family_Semibold);
      font-size: 20px;
      color: var(--text_color);
      @media (max-width: 600px) {
          font-size: 16px;
      }
  }
  .lead_generation_line{
      width: 80px;
      height: 5px;
      border-radius: 2.5px;
      background-color: var(--primary_brand_color);
      margin: 10px auto;
  }
  .lead_generation_text_field {
      margin-bottom: 25px;
      .lead_generation_text_field_container{
          position: relative;
          margin-bottom: 20px;
          .three_line.web {
              position: absolute;
              top: -15px;
              left: -30px;
          }
          .three_line.mobile {
              position: absolute;
              top: -10px;
              left: -10px;
          }
      }
  }
  .email_success {
      width: fit-content;
      .email_success_img {
          margin: 0 5px;
          width: 20px;
          height: 20px;
      }
      .email_success_text {
          font-family: var(--font_family_Semibold);
          font-size: 18px;
          font-weight: var(--font_weight_2);
      }
  }
  .keep_in_touch_text{
      font-size: 14px;
      font-weight: var(--font_weight_2);
      font-family: var(--font_family_Semibold);
  }
}
