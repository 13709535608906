.btn_container {
  width: 380px;
  height: 40px;
  margin: 40px auto 0 auto;
  border-radius: 6px;
  border: solid 1px #c4c4c4;
  display: flex;
  cursor: pointer;
  .inactive_btn {
    width: 50%;
    text-align: center;
    color: var(--text_color);
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: var(--font_family_Bold) !important;
  }
  .active_btn {
    width: 50%;
    text-align: center;
    color: var(--white);
    background-color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: var(--font_weight_2);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: var(--font_family_Bold) !important;
  }
  .active_btn.right {
    border-radius: 0 6px 6px 0;
  }
  .active_btn.left {
    border-radius: 6px 0 0 6px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
}
.oval{
  width: 6px;
  height: 6px;
  margin: 12px 5px 12px 0;
  border-radius: 50%;
  background-color: var(--primary_brand_color);
}
.description_secondary_txt_int{
  font-family: var(--font_family_Bold) !important;
}