.main_container {
    .label {
        font-family: var(--font_family_Semibold);
        font-size: 14px;
    }
    .step_centent {
        font-size: 12px;
        font-weight: var(--font_weight_0);
        font-family: var(--font_family_Medium);
        line-height: 1.5;
    }
    .stepperComp {
        :global(.MuiStepLabel-label.MuiStepLabel-alternativeLabel) {
            font-family: var(--font_family_Semibold);
            font-size: 14px;
        }
        :global(.MuiStepLabel-label.MuiStepLabel-completed) {
            color: var(--text_color_5);
        }
        .textBelow {
            text-align: center;
            color: var(--cyan-blue-shade);
            font-family: var(--font_family_Medium);
            font-size: 12px;
            font-weight: var(--font_weight_2);
            line-height: 1.5;
        }
        .fontWeight {
            font-weight: var(--font_weight_2);
            line-height: 1.5;
        }
        @media (max-width: 600px) {
            display: none;
        }
    }
    .stepperCompVertical {
        display: none;
        :global(.MuiStepLabel-label.MuiStepLabel-alternativeLabel) {
            font-family: var(--font_family_Semibold);
            font-size: 14px;
        }
        :global(.MuiStepLabel-label.MuiStepLabel-completed) {
            color: var(--text_color_5);
        }

        :global(.MuiStepLabel-root) {
            align-items: flex-start;
        }
        :global(.MuiStepLabel-label) {
            margin-left: 10px;
            margin-top: 1px;
        }
        .textBelow {
            text-align: left;
            color: var(--cyan-blue-shade);
            font-family: var(--font_family_Medium);
            font-size: 12px;
            padding-left: 25px;
            margin-left: 5px;
            font-weight: var(--font_weight_2);
        }
        .mb_text_below {
            padding-left: 4px; 
        }
        .fontWeight {
            font-weight: var(--font_weight_2);
        }
        .borderLeft {
            border-left: 2px solid var(--default);
        }
        .borderBottom {
            padding-bottom: 20px;
        }
        .borderLeft:last-child {
            padding-bottom: 20px;
        }
        .borderLeftCompleted {
            border-left: 2px solid var(--primary_brand_color);
            font-weight: var(--font_weight_2);
        }
        .borderLeftCompleted:last-child {
            padding-bottom: 20px;
        }
        :global(.MuiStepConnector-root) {
            display: none;
        }
        @media (max-width: 600px) {
            display: unset;
        }
    }
    .cancle_icon {
        width: 15px;
        height: 15px;
    }
}