  .cards_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 30px;
    @media (max-width: 600px) {
      margin-bottom: 10px;
    }
  }
  .home_page_title {
    color: var(--text_color);
    text-align: center;
  }
  .home_page_line {
    margin: 20px auto 25px;
    width: 80px;
    height: 5px;
    border-radius: 2.5px;
    background-color: var(--primary_brand_color);
  }
  .home_page_subtitle {
    margin-bottom: 10px;
    text-align: center;
  }

