.byod {
    .content_section{
        .byod_line{
            width: 80px;
            height: 5px;
            border-radius: 2.5px;
            background-color: var(--primary_brand_color);
        }
        .btn_section {
            .btn_cmp1, .btn_cmp2{
                width: 100%;
                max-width: 200px;
            }
        }
    }
}