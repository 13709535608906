@mixin dlg_font_styles($fs, $ff, $fw, $color) {
  @if $fs {
    font-size: $fs !important;
  }
  @if $ff {
    font-family: $ff !important;
  }
  @if $fw {
    font-weight: $fw !important;
  }
  @if $color {
    color: $color !important;
  }
}
.title {
  margin: 0 37px 10px !important;
  line-height: 1.77 !important;
  text-align: center !important;
  @include dlg_font_styles(
    1rem,
    var(--font_family_Bold),
    bold,
    var(--text_color_3)
  );
}
.label_txt {
  @include dlg_font_styles(
    16px,
    var(--font_family_Bold),
    bold,
    var(--text_color_3)
  );
}
.btn_container {
  margin-top: 25px;
  text-align: center !important;
}
.note {
  @include dlg_font_styles(
    12px,
    var(--font_family_Bold),
    bold,
    var(--text_color_1)
  );
  line-height: 1.58 !important;
  letter-spacing: normal !important;
}
