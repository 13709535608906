.content_carousel {
  top: 30%;
  width: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 4%;
  @media (min-width: 720px) and (max-width: 1024px) {
    width: 55%;
    top: 40%;
  }
  @media (max-width: 600px) {
    top: 30%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 95%;
  }
  .main_title {
    font-size: 62px !important;
    color: var(--text_color_9);
    font-family: var(--font_family_Bold);
    line-height: 1;
    @media (min-width: 600px) and (max-width: 1024px) {
      font-size: 42px !important;
      font-family: var(--font_family_Medium) !important;
    }
    @media (max-width: 600px) {
      font-size: 28px !important;
      margin: auto;
    }
  }
  .sub_title {
    font-size: 28px !important;
    color: var(--text_color_8);
    font-family: var(--font_family_Bold) !important;
    @media (min-width: 600px) and (max-width: 1024px) {
      font-size: 20px !important;
      font-family: var(--font_family_Medium) !important;
    }
    @media (max-width: 600px) {
      font-size: 20px !important;
      font-family: var(--font_family_Semibold) !important;
    }
  }
  .msg_style {
    width: 90%;
    a {
      color: var(--text_color_9);
      text-decoration: none;
    }
    color: var(--text_color_8);
    font-family: var(--font_family_Medium) !important;
    font-size: 16px;
    line-height: 25px;
    @media (min-width: 600px) and (max-width: 1024px) {
      font-size: 15px !important;
      line-height: 22px;
      width: 100%;
    }
    @media (max-width: 600px) {
      font-size: 14px !important;
      line-height: 21px;
      width: 85%;
      margin: auto;
      font-family: var(--font_family_Semibold) !important;
    }
  }
  .ticks_main_div {
    margin-bottom: 8px;
    @media (min-width: 600px) and (max-width: 1024px) {
      width: 80%;
    }
    // @media (max-width: 600px) {
    //   margin: 20px 0;
    // }
  }
  .button2 {
    padding-top: 10px;
    font-size: 16px;
    font-weight: var(--font_weight_4);
    color: var(--primary_color);
    font-family: var(--font_family_Bold);
  }
}
