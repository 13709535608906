.ac_status_succes {
  .title {
    font-family: var(--font_family_Bold);
    font-weight: var(--font_weight_4);
    font-size: 22px;
    text-align: center;
    color: var(--text_color);
    margin-bottom: 15px;
    margin-top: 8px;
  }
  .subtitle {
    font-family: var(--font_family_Medium);
    font-size: 14px;
    text-align: center;
    color: var(--text_color);
  }
  .line {
    width: 80px;
    margin: 15px auto 20px;
    height: 5px;
    border-radius: 2px;
    background-color: var(--primary_brand_color);
  }
  .logout_btn {
    font-family: var(--font_family_Bold);
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
    color: var(--primary_color);
    cursor: pointer;
    text-transform: none;
  }
  .button_contact {
    margin: auto;
  }
}
