.commited_awesome_div {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (min-width: 900px) {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .commited_awesome_text_wrap {
    margin: 3rem 0px 3rem 0px;
    display: flex;
    flex-direction: column;
    .commited_awesome_text {
      font-family: var(--font_family_Semibold);
      font-size: 15px;
      font-weight: var(--font_weight_2);
      line-height: 1.6;
      letter-spacing: normal;
      color: var(--black);
      text-align: left;
      @media (max-width: 900px) {
        text-align: center;
        padding-left: 40px;
        padding-right: 40px;
      }
      @media (max-width: 900px) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
  .commited_awesome_sliders {
    display: flex;
    flex-direction: column;
    .slider_active_buttons {
      font-size: 14px;
      font-family: var(--font_family_bold);
      font-weight: bold;
      color: var(--black);
    }
    .slider_disabled_buttons {
      font-size: 14px;
      font-family: var(--font_family_bold);
      font-weight: bold;
      color: var(--black);
      opacity: 0.3;
      background-color: var(--light-silver_color);
    }
    .MuiMobileStepper-root {
      background-color: var(--footer_background_color);
      justify-content: flex-end;
      @media (max-width: 1024px) {
        justify-content: center;
      }
    }
    .MuiButton-text {
      font-size: 14px;
      color: var(--text_color);
    }
    .MuiButton-label {
      font-family: var(--font_family) !important;
    }
    :global(.css-26w9jf-MuiMobileStepper-dot) {
      background-color: var(--black) !important;
    }
    .MuiButtonBase-root.Mui-disabled {
      color: var(--light-silver_color);
    }
        .individual_div {
          width: 33%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: pointer;
          .title {
            font-size: 25px;
            font-weight: bold;
            font-family: var(--font_family_bold);
          }
          .subtext {
            opacity: 0;
            width: 90%;
            margin: 0 auto;
            max-height: 0px;
            font-family: var(--font_family_Semibold);
            font-weight: var(--font_weight_2);
            @media (max-width: 1024px) {
              opacity: 1;
              max-height: unset;
            }
          }
          @media (max-width: 600px) {
            width: 100%;
            height: 280px;
          }
        }
        @media screen and (max-width: 600px) {
          .individual_div {
            width: 90%;
          }
        }
        .individual_div:hover .subtext {
          @media (min-width: 1025px) {
            transition: all 0.3s ease;
            opacity: 1;
            max-height: 40px;
          }
        }
        @media (max-width: 768px) {
          height: 46%;
        }
        @media (max-width: 600px) {
          flex-direction: column;
          height: 50%;
        }
      @media (max-width: 600px) {
        height: auto;
      }
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  @media screen and (max-width: 900px) {
    .commited_awesome_sliders {
      padding-left: 48px !important;
      padding-right: 24px;
    }
  }
  @media screen and (max-width: 600px) {
    .commited_awesome_sliders {
      padding-right: 0px !important;
    }
  }
}
