.bring_your_phone_main {
  @media (max-width: 1024px) {
    margin-top: 70px !important;
  }
  .bring_your_phone_content {
    .compatibility_plan_title {
      font-family: var(--font_family_Bold) !important;
      font-size: 22px;
      font-weight: var(--font_weight_4);
      font-stretch: normal;
      font-style: normal;
      line-height: 1.77;
      letter-spacing: normal;
      text-align: center;
      color: var(--text_color_1);
      margin-top: 50px;
      @media (max-width: 600px) {
        margin-bottom: 25px;
      }
    }
    .compatibility_plan_input {
      .limit_div {
        font-family: var(--font_family_Medium);
        font-weight: var(--font_weight_0);
        display: flex;
        font-size: 12px;
        justify-content: flex-end;
        margin: 10px 0;
      }
      .phone_trouble_div {
        .having_trouble_div {
          color: var(--primary_color);
          font-family: var(--font_family_Bold) !important;
          font-size: 14px;
          cursor: pointer;
          text-align: center;
        }
        .above_email_text {
          font-size: 14px;
          font-family: var(--font_family_Medium);
          font-weight: var(--font_weight_0);
        }
      }
    }
    .success_compatibility {
      .back_button {
        color: var(--text_color);
        font-size: 1rem;
        padding-bottom: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .arrow_icon {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-top: -2px;
      }
      .individual_row {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        border-bottom: solid 1px var(--border_color);
        .text_left {
          color: var(--grey_shade);
          font-size: 16px;
          font-family: var(--font_family_Semibold);
        }
        .text_right {
          color: var(--text_color);
          font-size: 16px;
          font-family: var(--font_family_Semibold);
          @media (max-width: 600px) {
            margin-top: 10px;
          }
        }
        @media (max-width: 600px) {
          justify-content: flex-start;
          flex-direction: column;
        }
      }
    }
  }
}
.compatibility_plan_bring {
  background: var(--white);

  .bummer_div {
    .modal_image {
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
    .bummer_content {
      font-family: var(--font_family_Semibold);
      font-size: 14px;
      line-height: 1.6;
      text-align: center;
      color: var(--text_color);
      padding: 20px 0;
    }
    .shop_phone_button {
      padding: 0px 0 20px 0;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media (max-width: 600px) {
        width: 100%;
        flex-direction: column;
        padding: 0;
      }
    }

    .try_again {
      font-size: 14px;
      font-family: var(--font_family_Semibold);
      color: var(--primary_color);
      display: flex;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 600px) {
       margin-top: 10px;
      }
    }
    @media (max-width: 600px) {
      width: 100%;
    }
  }
}
.have_trouble_tool_tip {
  padding: 20px;
  .have_trouble_header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .close_icon {
      height: 15px;
      width: 15px;
      cursor: pointer;
      color: var(--black);
      opacity: 0.5;
    }
    .have_trouble_header_text {
      font-family: var(--font_family_Bold);
      font-weight: var(--font_weight_4);
      font-size: 16px;
      margin: 0 auto;
    }
  }
  .have_trouble_detail_div {
    display: flex;
    .detail_img_div {
      margin-right: 15px;
      .detail_img {
        width: 23px;
        height: 28px;
      }
    }
    .detail_content {
      .detail_head {
        color: var(--text_color_5);
        font-family: var(--font_family_Semibold);
        font-size: 14px;
      }
      .detail_subtext {
        font-family: var(--font_family_Medium);
        font-size: 14px;
        color: var(--text_color);
      }
      .add_margin_bottom {
        margin-bottom: 15px;
      }
    }
  }
}
.accordion_component {
  margin: 0 auto 20px auto;
  .heading_accordian {
    padding: 30px 10px 20px 10px;
    font-family: var(--font_family_Bold);
    font-size: 16px;
    font-weight: var(--font_weight_4);
    color: var(--text_color);
  }
}
:global(.MuiAccordionSummary-root) {
  font-size: 14px;
  font-weight: var(--font_weight_2);
  cursor: pointer;
}
:global(.MuiAccordionSummary-content) {
  height: 40px !important;
  font-family: inherit;
}
:global(.MuiAccordionDetails-root) {
  font-family: var(--font_family_Medium);
  font-size: 14px;
  font-weight: var(--font_weight_0);
  line-height: 1.75;
  letter-spacing: normal;
  color: var(--accordion-body_color);
  padding: 1rem 0.7rem;
  text-align: left;
}
:global(.MuiPopper-arrow::before) {
  filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 3.08)) !important;
}
:global(.css-10yqk1k-MuiPopper-root){
  z-index: 2 !important;
}