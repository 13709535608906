.content_carousel {
  width: 635px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 2%;

  @media (max-width: 600px) {
    top: 30%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 80%;
  }
  @media (601px < width < 1120px) and (orientation: portrait) {
    width: 60%;
  }
  @media (1120px < width < 1301px) and (orientation: portrait) {
    width: 65%;
  }

  .ticks_main_div {
    margin-bottom: 25px;
    @media (max-width: 600px) {
      margin: 20px 0;
    }
  }
  .button2 {
    padding-top: 10px;
    font-size: 16px;
    font-weight: var(--font_weight_4);
    color: var(--primary_color);
    font-family: var(--font_family_Bold);
  }
}
