.coverage_wrap {
  @media (min-width: 600px) and (max-width: 1024px) {
    height: 140px;
  }
  @media (max-width: 600px) {
    height: 180px;
  }
  .coverage_check {
    width: 635px;
    margin-left: 6%;

    @media (min-width: 600px) and (max-width: 1024px) {
      width: 60%;
      margin-top: 20px;
      height: 140px;
    }
    @media (max-width: 600px) {
      width: 90%;

      height: 130px;
      margin-top: 20px;
    }
    .coverage_heading {
      font-size: 18px;
      font-family: var(--font_family_Bold);
      color: var(--text_color_8);
      margin-bottom: 15px;
      text-align: left;
    }
    .note_style {
      font-family: var(--font_family_Bold);
    }

    .check_coverage {
      background-color: var(--gray_background);
    }

    #iframeMapId {
      width: 100%;
      height: 100vh;
      border-width: 0px;
    }
    .submit_btn {
      min-width: 70px !important;
    }
    .address_holder {
      display: flex;
      height: 42px;
      box-sizing: border-box;
      border: 1px solid var(--primary_color);
      background-color: var(--white);
    }
    .textField > div {
      border-radius: 0 !important;
      border: none !important;
    }
    .coverage_error_msg > span {
      font-family: var(--font_family_Semibold) !important;
    }
    .try_again_btn {
      font-size: 18px !important;
      // width: 162px;
      white-space: nowrap;
      font-family: var(--font_family_Semibold) !important;
      border: none;
      background-color: transparent;
      text-decoration: underline;
      color: var(--primary_color);
      cursor: pointer;
      padding: 0;
    }
  }
}
