.help_support {
  .line {
    border-radius: 2px;
    background-color: var(--success) !important;
  }
  .help_center_main,
  .support_main {
    background-color: var(--white) !important;
  }
  .support_main {
    font-family: var(--font_family_Semibold) !important;
    color: hsl(214, 34%, 42%);
    line-height: 2.3;
  }
  .support_header_text,
  .help_center_header {
    font-family: var(--font_family) !important;
    font-size: 18px;
    color: var(--black);
    font-weight: var(--font_weight_4);
  }
  .links {
    font-family: var(--font_family_Semibold);
    color: var(--help_support_links);
  }
  .chat_icon_size {
    width: 24px;
    height: 24px;
  }
  .email_icon_size {
    width: 23px;
    height: 17px;
  }
  .phone_icon_size {
    width: 20px;
    height: 20px;
  }
  .support_time {
    font-family: var(--font_family_Semibold) !important;
  }
  .contact_address_text {
    font-family: var(--font_family_Semibold);
    font-size: 16px;
    font-weight: var(--font_weight_2);
    line-height: 1.73;
    color: var(--text_color_3);
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .help_support {
    margin-top: 70px !important;
  }
}
