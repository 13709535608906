.buy_data_dialog {
  background-color: var(--white);
  width: 500px !important;
  padding: 25px !important;
  :global(.MuiTypography-root) {
    top: 0;
    left: 0;
  }
  :global(.MuiSvgIcon-root) {
    margin: 10px;
    opacity: 0.3rem !important;
  }
  .dialog_main {
    width: 100%;
    .dialog_heading {
      font-size: 22px;
      text-align: center;
      color: var(--text_color);
      margin-bottom: 15px;
      padding-bottom: 0;
      font-family: var(--font_family_Bold);
    }
    .inner_container {
      .row_container{
        display: flex;
        justify-content: space-between;
        padding: 15px 0;
        width: 100%;
        .total_text {
          font-family: var(--font_family_Semibold);
          font-size: var(--description_secondary_text_fs_desktop);
          font-weight: var(--font_weight_2);
          line-height: 1;
          color: var(--text_color_5);
        }
      }
      .row_divider {
        border-bottom: solid 1px var(--grey_color_1);
        opacity: 0.3;
      }
      .card_text {
        font-size: 14px;
        font-weight: var(--font_weight_4);
        text-align: center;
        color: var(--text_color_3);
        font-family: var(--font_family_Bold);
      }
      .change_card {
        color: var(--primary_color);
        cursor: pointer;
        padding-left: 20px;
      }
    }
  }
}