.brandFeatureHeading {
  font-size: 32px !important;
  font-weight: var(---description_text_font_weight) !important;
  font-family: var(--font_family_Medium) !important;
  color: var(--text_color_8);
  // text-align: left !important;
  @media (max-width: 1024px) {
    text-align: center !important;
    width: 80%;
    margin: auto !important;
  }
  @media (max-width: 600px) {
    font-size: 26px !important;
  }
}
.brandFeatureDescription {
  font-size: var(--coverage_title_fs_desktop) !important;
  font-family: var(--font_family_Semibold) !important;
  font-weight: var(---description_text_font_weight) !important;
  color: var(--text_color_8);
}
.featureHeading {
  font-size: var(--page_header_font_size_desktop) !important;
  font-weight: var(---description_text_font_weight) !important;
  font-family: var(--font_family_Medium) !important;
  color: var(--text_color_8);
}
.featureDescription {
  font-size: var(--main_banner_sub_title_fs_mobile) !important;
  font-weight: var(---description_text_font_weight) !important;
  font-family: var(--font_family_Semibold) !important;
  color: var(--text_color_8);
  @media (max-width: 1024px) {
    text-align: center !important;
  }
}
