.banner {
  height: 250px;
  width: 100%;
  background-color: var(--primary_color);
  .bannerText {
    font-size: 72px !important;
    line-height: 1.43;
    padding: 70px 85px;
    color: var(--white);
    font-family: var(--font_family_Medium);
    background-color: var(--primary_brand_color);
  }
}
.terms_content {
  width: 85%;
  margin: 0 auto 50px;

  @media screen and (max-width: 500px) {
    .content {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  @media screen and (min-width: 1600px) {
    .content {
      margin-left: 190px;
      margin-right: 190px;
    }
  }
  .terms_title {
    font-family: var(--font_family_Semibold) !important;
    text-align: left;
    color: var(--primary_color);
    font-size: 42px !important;
    letter-spacing: 0.06px;
    line-height: 46px;
    margin-bottom: 50px;
  }

  .terms_headings {
    font-family: var(--font_family_Semibold);
    font-size: 26px;
    letter-spacing: 0.04px;
    line-height: 29px;
    margin-top: 40px;
  }

  .terms_para {
    margin: 20px 0px 20px 0px;
    font-family: var(--font_family_Semibold);
    font-size: 22px;
    letter-spacing: 0;
    line-height: 26px;
  }
  a {
    text-decoration: underline;
    color: var(--primary_color);
    cursor: pointer;
  }

  p {
    margin: 20px 0px 20px 0px;
    font-family: var(--font_family_Semibold);
    font-size: 22px;
    letter-spacing: 0;
    line-height: 26px;
  }

  li {
    font-family: var(--font_family_Semibold);
    font-size: 22px;
    letter-spacing: 0;
    line-height: 26px;
    list-style: none;
    margin: 20px 50px 40px 50px;
    flex-wrap: wrap;
    div {
      font-family: var(--font_family_Semibold);
      font-size: 22px;
      letter-spacing: 0;
      line-height: 26px;
      list-style: none;
      margin: 10px 50px 10px 50px;
      flex-wrap: wrap;
    }
  }
  .caps_para {
    font-family: var(--font_family_Medium);
    font-size: 16px;
    font-weight: var(--font_weight_4);
    line-height: 1.7;
    letter-spacing: normal;
    color: var(--text_color_3);
    text-align: left;
    margin: 20px 0px 20px 0px;
  }
}

.terms_line {
  width: 80px;
  height: 5px;
  border-radius: 2.5px;
  background-color: var(--primary_brand_color);
  margin: 10px auto 50px auto;
}
