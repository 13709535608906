@mixin textStyle($fontSize, $fontFamily, $fontWeight, $color) {
  font-family: $fontFamily !important;
  font-size: $fontSize !important;
  font-weight: $fontWeight !important;
  color: $color !important;
}
.manage_card_container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .card_heading {
    @include textStyle(
      20px,
      var(--font_family_Bold),
      var(--font_weight_4),
      var(--text_color)
    );
    line-height: 1.1;
  }
  .description {
    @include textStyle(
      14px,
      var(--font_family_Medium),
      var(--font_weight_1),
      var(--text_color)
    );
    line-height: 1.43;
    padding-bottom: 20px;
    margin: 0 auto;
  }
  .bottom_section {
    padding: 10px 15px;
    margin: 0 auto;
    width: 90% !important;
    .save_card_text {
      @include textStyle(
        16px,
        var(--font_family_Bold),
        var(--font_weight_0),
        var(--text_color_3)
      );
      text-align: left !important;
    }
    .number_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto 15x;
      padding-top: 15px !important;
      padding: 0px 0px 20px;
      border-top: 1px solid rgba(151, 151, 151, 0.3);
      text-align: left !important;
      .expiry {
        @include textStyle(
          14px,
          var(--font_family_Medium),
          var(--font_weight_1),
          var(--text_color)
        );
        line-height: 1.43;
        margin: 0 auto;
        margin-bottom: 3px;
      }
      .card_number {
        @include textStyle(
          14px,
          var(--font_family_Semibold),
          var(--font_weight_2),
          var(--text_color_2)
        );
        line-height: 1.29;
        margin-bottom: 3px;
      }
      .update_text {
        @include textStyle(
          14px,
          var(--font_family_Bold),
          bold,
          var(--primary_color)
        );
        align-self: flex-start;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}
.left_arrow_icon {
  position: absolute;
  width: 15px;
  height: 18px;
  cursor: pointer;
  left: 0;
  margin-left: 20px;
  margin-top: 7px;
}
.add_card {
  @include textStyle(16px, var(--font_family_Bold), bold, var(--primary_color));
}

.manage_cards_dialog {
  background-color: var(--white);
  .bottom_section {
    padding: 0;
    margin: 0;
    width: 100% !important;
  }
}