.our_partners {
    .our_partners_line {
        width: 80px;
        height: 5px;
        border-radius: 2.5px;
        background-color: var(--primary_brand_color);
    }
    .partner_content{
        font-size: 16px;
        .partner_description{
            span {
                font-weight: bold;
                line-height: 25px;
                font-family: var(--font_family_Bold);
            }
            letter-spacing: normal;
        }
        .content_link {
            color: var(--dusk);
            cursor: pointer;
            font-size: 16px;
            font-weight: bold;
        }
    }
}