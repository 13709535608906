.account_change_new_plan_content {
  width: 400px;
  @media (max-width: 600px) {
    width: 90%;
  }
  .account_new_current_head {
    font-family: var(--font_family_Bold);
    font-size: 22px;
    line-height: 1;
    text-align: center;
    margin-top: 20px;
  }
  .confirm_plan_line {
    width: 80px;
    height: 5px;
    border-radius: 2px;
    background-color: var(--primary_brand_color);
    margin: 15px auto 30px auto;
  }
  .account_new_current_cont {
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
    @media (max-width: 600px) {
      padding: 0px 0px;
    }

    .account_cur_new_head {
      font-family: var(--font_family_Medium);
      font-size: 14px;
      line-height: 1;
      color: var(--text_color_5);
    }
    .current_bill_date {
      font-family: var(--font_family_Bold);
      font-size: 14px;
      line-height: 1;
      color: var(--text_color_5);
      padding-bottom: 10px;
    }
    .bold {
      font-family: var(--font_family_Semibold) !important;
    }
    .account_cur_new_content {
      display: flex;
      justify-content: space-between;
      padding-bottom: 15px;
    }
    .account_cur_new_border {
      height: 1px;
      margin-bottom: 15px;
      opacity: 0.3;
      border: 1px solid var(--grey_color_1);
      background-color: var(--grey_color_1);
    }
  }
}
