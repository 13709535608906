
.how_it_works {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .how_it_works_line {
        width: 80px;
        height: 5px;
        border-radius: 2.5px;
        background-color: var(--primary_brand_color);
    }
}