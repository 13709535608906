.skip_to_checkout {
  margin-top: 15px !important;
  font-size: 14px !important;
  font-weight: var(--font_weight_2) !important;
  font-family: var(--font_family_Bold) !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary_color);
  cursor: pointer;
}
.checkIMEI_modal_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.compatibility_plan_input {
  width: 350px;
  .msg {
    margin-bottom: 12px;
    padding-top: 5px;
    display: flex;
    justify-content: space-between;

    .limit_div {
      font-size: 12px;
      font-weight: var(--font_weight_1);
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: var(--text_color);
    }
    .having_trouble_div {
      margin-top: 4px;
      font-size: 14px;
      font-weight: var(--font_weight_2);
      font-family: var(--font_family_Bold);
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--primary_color);
      cursor: pointer;
      height: 20px;
    }
  }
  .check_coverage_input_box {
    display: flex;

    img {
      height: 54px;
      margin-left: -70px;
      z-index: 1;
      object-fit: contain;
      cursor: pointer;
      @media (max-width: 600px) {
        height: 54px;
        right: 13px;
      }
    }
    .checkImei_loader {
      margin-left: -63px;
      z-index: 1;
      height: 54px;
      width: 63px;
      object-fit: contain;
      background-color: var(--primary_brand_color);
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--white_3);
      border-radius: 2px;
      @media (max-width: 600px) {
        top: 200px;
        right: 13px;
      }
    }
    .eSim_icon {
      height: 54px;
      object-fit: contain;
      cursor: auto;
      @media (max-width: 600px) {
        top: 200px;
        right: 13px;
      }
    }
    .retry_icon {
      @media (max-width: 600px) {
        top: 200px;
      }
    }
    .failed {
      color: var(--danger);
    }
  }
  @media (max-width: 600px) {
    width: 325px;
  }
}
.have_trouble_tool_tip {
  padding: 20px;
  .have_trouble_header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .close_icon {
      height: 15px;
      width: 15px;
      cursor: pointer;
      color: var(--black);
      opacity: 0.5;
    }
    .have_trouble_header_text {
      font-family: var(--font_family_Bold);
      font-weight: var(--font_weight_4);
      font-size: 16px;
      margin: 0 auto;
    }
  }
  .have_trouble_detail_div {
    display: flex;
    .detail_img_div {
      margin-right: 15px;
      .detail_img {
        width: 23px;
        height: 28px;
      }
    }
    .detail_content {
      .detail_head {
        color: var(--text_color_5);
        font-family: var(--font_family_Semibold);
        font-size: 14px;
      }
      .detail_subtext {
        font-family: var(--font_family_Medium);
        font-size: 14px;
        color: var(--text_color);
      }
      .add_margin_bottom {
        margin-bottom: 15px;
      }
    }
  }
}
