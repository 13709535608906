.line_count_button {
  border-radius: 5px;
  border: 1px solid var(--primary_color);
  background-color: var(--white);
  font-size: 14px;
  width: 2.188em;
  height: 2.188em;
  color: var(--primary_color);
  font-family: var(--font_family);
  line-height: 1.5;
  cursor: pointer;
}
.button_disable {
  border: 1px solid var(--lightgrey_4);
  background-color: var(--white);
  color: var(--lightgrey_4);
  cursor: not-allowed;
}

