.cards_div {
  width: 286px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 23, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 104px;
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .plan_heading {
    color: var(--text_color_9);
    text-align: center;
    height: 88px;
    font-size: var(--coverage_title_fs_desktop) !important;
    text-transform: uppercase;
    line-height: 24px;
  }
  .plan_price {
    height: 111px;
    width: 288px;
    background-color: var(--grey_shade_4);
  }
  .feature_content {
    // display: flex;
    width: 226px;
    // height: 100px;
    margin: 20px auto 40px;
    .line {
      height: 1px;
      width: 226px;
      border-bottom: 1px solid #cccccc;
    }
    .plan_feature {
      font-size: var(--main_banner_sub_title_fs_mobile);
      margin: 11px auto;
    }
  }
  .proceed_btn {
    color: var(--white);
    border-radius: 0% !important;
  }
}
