.payment_modal {
  width: 500px !important;
  background-color: var(--white);
  :global(.MuiTypography-root) {
    top: 0;
    left: 0;
  }
  :global(.MuiSvgIcon-root) {
    opacity: 0.5 !important;
    height: 26px !important;
    width: 26px !important;
    margin: 10px 15px;
  }
  .inner_wrapper {
    width: 100%;
    font-size: 14px !important;
    .modal_header {
      font-family: var(--font_family_Bold) !important;
      font-size: 22px !important;
      font-weight: var(--font_weight_4) !important;
      text-align: center;
      padding: 0px 0px 10px;
      line-height: 1;
    }
    .plan_sub_content > *,
    .plan_header {
      font-size: 14px !important;
      padding-bottom: 15px;
      color: var(--text_color_5);
      font-family: var(--font_family_Medium);
    }
    .new_plan > *,
    .plan_sub_content > * {
      font-family: var(--font_family_Semibold) !important;
      font-weight: var(--font_weight_1) !important;
      line-height: 1 !important;
    }
    .plan_sub_content {
      display: flex;
      justify-content: space-between;
    }
    .current_plan {
      .plan_sub_content > * {
        font-family: var(--font_family_Medium) !important;
        font-weight: var(--font_weight_1) !important;
      }
    }
    .modal_content2 {
      .account_note {
        padding: 20px 0;
      }
      .button_wrapper,
      .card_details {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .card_details > * {
        font-family: var(--font_family_Bold);
        font-weight: var(--font_weight_0);
      }
      .button_wrapper {
        .update_later {
          font-family: var(--font_family_Bold);
          font-size: 14px;
          text-align: center;
          color: var(--primary_color);
          cursor: pointer;
        }
      }
    }
    .modal_content2 > * {
      text-align: center;
      font-size: 15px !important;
      font-family: var(--font_family_Medium) !important;
      line-height: 1.5;
    }
    hr {
      color: var(--grey_color_1);
      margin-top: 0;
      margin-bottom: 15px;
      border: 1px solid;
      opacity: 0.3;
    }
  }
}
