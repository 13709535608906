// @mixin buttonStyle($height, $bgcolor, $border, $color, $font-size, $text-transform, $text-decoration, $borderRadius, $min-width) {

@mixin buttonStyle(
  $bgcolor,
  $borderColor,
  $color,
  $borderRadius,
  $borderWidth: 0.0625rem,
  $height: 2.8125em,
  $text-transform: none
) {
  height: $height !important;
  background-color: $bgcolor !important;
  border: $borderWidth solid $borderColor !important;
  color: $color !important;
  font-size: 1em !important;
  font-weight: var(--font_weight_1) !important;
  line-height: 1.125em !important;
  text-align: center !important;
  letter-spacing: 0 !important;
  font-family: var(--font_family_Bold) !important;
  text-transform: $text-transform !important;
  min-width: 200px !important;
  @media (max-width: 360px) {
    min-width: 100px !important;
  }
  border-radius: $borderRadius !important;
  :global(.MuiCircularProgress-colorPrimary) {
    color: $color !important;
    width: 35px !important;
    height: 35px !important;
  }
}

.custom_primary_outlined_rounded {
  @include buttonStyle(
    var(--white),
    var(--primary_color),
    var(--primary_color),
    25px
  );
}
.custom_primary_contained_rounded {
  @include buttonStyle(
    var(--primary_color),
    var(--primary_color),
    var(--white),
    25px
  );
}
.custom_secondary_contained_rounded {
  @include buttonStyle(var(--dusk), var(--dusk), var(--white), 25px);
}
.custom_secondary_transparent_brand_rounded {
  @include buttonStyle(
    transparent,
    var(--primary_color),
    var(--primary_color),
    25px
  );
}
.custom_secondary_transparent_white_rounded {
  @include buttonStyle(transparent, var(--white), var(--white), 25px);
}
.custom_primary_outlined_square {
  @include buttonStyle(
    var(--white),
    var(--primary_color),
    var(--primary_color),
    4px
  );
}
.custom_primary_contained_square {
  @include buttonStyle(
    var(--primary_color),
    var(--primary_color),
    var(--white),
    4px
  );
}

.primary_contained_square_no_radius {
  @include buttonStyle(
    var(--primary_color),
    var(--primary_color),
    var(--white),
    0px,
    0.0625rem,
    40px,
    uppercase
  );
}

.custom_secondary_contained_square {
  @include buttonStyle(var(--dusk), var(--dusk), var(--white), 4px);
}
.custom_secondary_transparent_brand_square {
  @include buttonStyle(
    transparent,
    var(--primary_color),
    var(--primary_color),
    4px
  );
}
.custom_secondary_transparent_white_square {
  @include buttonStyle(transparent, var(--white), var(--white), 4px);
}
.custom_disabled_button {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

.secondary_transparent_white_square_no_radius {
  @include buttonStyle(
    transparent,
    var(--white),
    var(--white),
    0px,
    1.5px,
    2.8125em,
    uppercase
  );
}
