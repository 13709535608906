.referal {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px dashed var(--text_color);
  margin-top: 14px;
  cursor: pointer;
  .left_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: var(--font_family_Semibold);
    color: var(--primary_brand_color);
    .referalText {
      margin-left: 10px;
      color: var(--primary_brand_color);
      font-family: var(--font_family_Semibold);
    }
    img {
      margin-right: 10px;
    }
    .referal_text {
      color: var(--text_color_11);
      font-family: var(--font_family_Semibold);
    }
    .referal_text_light {
      font-family: var(--font_family_Medium);
      color: var(--primary_brand_color);
      font-size: 14px;
    }
    .delay_coupon_text {
      font-family: var(--font_family_Semibold);
      font-size: 14px;
      color: var(--primary_brand_color);
    }
  }
  .right_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    .cost {
      font-size: 14px;
      font-weight: var(--font_weight_4);
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--primary_brand_color);
    }
    .remove {
      font-family: var(--font_family_Semibold);
      font-size: 14px;
      color: var(--primary_brand_color);
      cursor: pointer;
    }
    @media (max-width: 768px) {
      width: 20%;
    }
  }
}

.input_group_bill {
  width: 100%;
  display: flex;
  :global(.MuiFilledInput-root) {
    border-radius: 6px 0 0 6px;
  }
  .input_group_gb {
    cursor: pointer;
    height: 8px;
    display: flex;
    align-items: center;
    padding: 0.875rem 0.75rem;
    font-size: 1rem;
    font-weight: var(--font_weight_2);
    color: var(--white);
    white-space: nowrap;
    background-color: var(--primary_brand_color);
    border: 1px solid var(--primary_brand_color);
  }
}
.error_message {
  color: var(--danger);
  font-size: 12px;
  font-family: var(--font_family_Medium);
  line-height: 24px;
  text-align: left;
  margin: 5px 5px 0 5px;
  display: block;
}
