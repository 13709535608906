.activationIsMnpMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .heading_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    .heading {
      font-family: var(--font_family_Bold);
      font-size: 22px;
      font-weight: var(--font_weight_4);
      line-height: 22px;
      text-align: center;
      color: var(--text_color);
      margin-bottom: 20px;
    }
    .back_button {
      font-family: var(--font_family_Semibold);
      font-size: 20px;
      font-weight: var(--font_weight_2);

      color: var(--text_color);
      cursor: pointer;
      .arrow_icon {
        width: 18px;
        height: 18px;
        margin: 0 5px -2px 0;
      }
    }
  }
  .sub_heading {
    display: flex;
    justify-content: center;
    padding: 0 20px;
    font-family: var(--font_family_Semibold);
    font-size: 14px;
    text-align: center;
    font-weight: var(--font_weight_2);
    line-height: 20px;
    color: var(--text_color_2);
    line-height: 1.43;
  }
  .activation_is_mnp_container {
    display: flex;
    justify-content: center;
    border-radius: 6px;
    box-shadow: 0 0 1px 1px var(--cement_color);
    background-color: var(--lightgrey_6);
    .img_Icon {
      height: 30px;
      width: 30px;
      margin-bottom: 14px;
    }
    .title {
      font-family: var(--font_family_Semibold);
      font-size: 14px;
      font-weight: var(--font_weight_2);
      line-height: 20px;
      display: flex;
      text-align: center;
      justify-content: center;
      color: var(--text_color_2);
      margin-bottom: 5px;
    }
    .subtitle {
      display: flex;
      text-align: center;
      justify-content: center;
      letter-spacing: 0;
      font-family: var(--font_family_Medium);
      font-size: 14px;
      font-weight: var(--font_weight_1);
      line-height: 20px;
      color: var(--text_color);
      margin-bottom: 14px;
    }
  }
}
