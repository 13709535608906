.currentPlan {
    color: var(--primary_brand_color);
    margin: 5px 0;
    font-family: var(--font_family_Semibold);
}

.active_plan {
    cursor: not-allowed !important;
    opacity: 0.5;
}

.in_active_plan {
    cursor: pointer;
}

.active_item_label {
    color: var(--primary_brand_color);
    font-family: var(--font_family_Semibold);
}

.move_next_month_label {
    color: var(--date_picker_inp);
    font-size: 14px;
    font-family: var(--font_family_Semibold);
    margin-top: 10px;
}

.downgrade {
    cursor: not-allowed !important;
    opacity: 0.5;
}

.ActiveCard {
    background: var(--primary_brand_color) !important;
    color: var(--white) !important;
}

.CheckoutCard {
    border: 1px solid var(--card-color);
    border-radius: 4px;
    padding: 15px;
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;

    .leftDiv {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        width: 40%;

        .cardTitle {
            font-size: 16px;
            font-family: var(--font_family_Semibold);
        }

        .cardSubtext {
            font-size: 20px;
        }
    }

    .rightDiv {
        width: 60%;

        .gbData {
            font-family: var(--font_family_Semibold);
            font-size: 16px;
            text-align: right;

            .monthData {
                font-family: var(--font_family_Medium);
                color: var(--grey_shade);
                font-size: 14px;
            }

            .monthDataActive {
                font-family: var(--font_family_Medium);
                color: var(--white);
                font-size: 14px;
            }
        }

        .sharable {
            font-size: 12px;
            font-family: var(--font_family_Semibold);
            text-align: right;
        }

        .addLine {
            font-family: var(--font_family_Medium);
            font-size: 14px;
            text-align: right;

            .perMonth {
                font-family: var(--font_family_Semibold);
                font-size: 14px;
                margin-right: 5px;
            }
        }
    }
}

.selectPlanAndProceedBtn {
    width: 60%;
    margin: 20px auto;
}

.active_plan#actvie_card_border>.CheckoutCard {
    border: 2px solid var(--primary_brand_color);
}